function assertUnsToothNumberIsValid(unsToothNumber) {
  if (unsToothNumber < 1 || unsToothNumber > 32) {
    throw new Error(`Invalid tooth number: ${unsToothNumber}`);
  }
}

export function toFdi(unsToothNumber) {
  if (unsToothNumber === null) return null;

  unsToothNumber = Number(unsToothNumber);

  assertUnsToothNumberIsValid(unsToothNumber);

  if (unsToothNumber <= 8) {
    return 19 - unsToothNumber;
  } else if (unsToothNumber <= 16) {
    return unsToothNumber + 12;
  } else if (unsToothNumber <= 24) {
    return 55 - unsToothNumber;
  }

  return unsToothNumber + 16;
}

export function getToothLabel(tooth, notation) {
  return notation === 'UNS' ? tooth : toFdi(tooth);
}

export function isUpperArch(unsToothNumber) {
  unsToothNumber = Number(unsToothNumber);

  assertUnsToothNumberIsValid(unsToothNumber);

  return unsToothNumber <= 16;
}

export function areTeethOnOneArch(teethA, teethB) {
  return (isUpperArch(teethA) && isUpperArch(teethB)) || (!isUpperArch(teethA) && !isUpperArch(teethB));
}
