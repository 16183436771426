import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import SingleToothPicker from "./SingleToothPicker";
import {isUpperArch} from '../../utils/arch';

export default function SingleToothPickerDialog({
  title,
  onClose,
  tooth,
  onChange,
}) {
  const { t } = useTranslation();
  const [toothSelection, setToothSelection] = useState(tooth);
  const handleOk = () => {
    if (toothSelection != tooth) onChange(toothSelection);
    else onClose();
  };

  return (
    <Dialog onClose={onClose} open={true}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Box sx={{ m: 2 }}>
          <SingleToothPicker
            jaw={isUpperArch(tooth) ? 'upper' : 'lower'}
            value={toothSelection}
            onChange={setToothSelection}
          />
        </Box>
        <DialogActions>
          <Button onClick={onClose}>{t("Cancel")}</Button>
          <Button
            disabled={toothSelection === null}
            onClick={handleOk}
            variant="contained"
          >
            {t("Ok")}
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}
