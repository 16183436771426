import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useSearchParams } from "react-router-dom";
import Project from "../../model/Project";
import ProjectsFilter from "../../model/ProjectsFilter";
import ProjectState from "../../model/ProjectState";
import { useSnackbar } from "../../providers/SnackbarProvider";
import { useSettings } from "../../providers/UserProfileProvider";
import { useGetOrganizationsQuery } from "../../services/organizations";
import {
  useDeleteProjectMutation,
  useGetProjectsQuery,
  useUpdateProjectMutation,
} from "../../services/projects";
import { getToothLabel } from "../../utils/arch";
import OrganizationLinkLabel from "../Organizations/OrganizationLinkLabel";
import Timestamp from "../system/Timestamp";
import FeedbackLabel from "./FeedbackLabel";
import ProjectListHeader from "./ProjectListHeader";
import ProjectListToolbar from "./ProjectListToolbar";
import ProjectStateLabel from "./ProjectStateLabel";
import LoadingError from "../system/LoadingError";
import Loading from "../Loading";
import UserLabel from "../system/UserLabel";

function getComparator(order, orderBy) {
  const get = (x) => (orderBy === "state" ? x.state.value : x[orderBy]);
  const comp = (a, b) => (a < b ? -1 : a > b ? 1 : 0);
  return order === "desc"
    ? (a, b) => -comp(get(a), get(b))
    : (a, b) => comp(get(a), get(b));
}

export default function ProjectList({ organization, user }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [filters, setFilters] = useState(
    ProjectsFilter.fromQueryString(searchParams)
  );

  const query = {};
  if (organization) query.ownerOrganization = organization;
  if (user) query.ownerUser = user;
  if (filters.state.has(ProjectState.ARCHIVED)) {
    query.state = "archived";
  }

  const { showAlert } = useSnackbar();
  const { notation } = useSettings();
  const [deleteProject] = useDeleteProjectMutation();
  const [updateProject] = useUpdateProjectMutation();
  const { data, error, isLoading } = useGetProjectsQuery(query);
  const { t } = useTranslation();

  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("updatedAt");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(
    parseInt(new URLSearchParams(searchParams).get("rowsPerPage") ?? 10)
  );

  const headCells = [
    {
      id: "refNumber",
      label: t("Reference Number"),
    },
    {
      id: "owner",
      label: t("Organization"),
    },
    {
      id: "teethUnderRepair",
      label: t("Teeth"),
    },
    {
      id: "state",
      label: t("State"),
    },
    {
      id: "feedback",
      label: t("Feedback"),
    },
    {
      id: "createdAt",
      label: t("Created"),
    },
    {
      id: "updatedAt",
      label: t("Updated"),
    },
  ];

  // Convert to model and filter
  const rows = (data || [])
    .map((p) => new Project(p))
    .filter((p) => filters.matches(p));

  const extendedView = !organization && !user;
  const { data: organizations } = useGetOrganizationsQuery(query, {
    skip: !extendedView,
  });

  if (isLoading) return <Loading />;
  else if (error) return <LoadingError error={error} />;

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const urlParams = new URLSearchParams(searchParams);
    urlParams.set("rowsPerPage", event.target.value);
    setSearchParams(urlParams, { replace: true });
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDeleteProject = () => {
    for (const p of selected)
      deleteProject(p)
        .unwrap()
        .catch((err) => showAlert(t("Failed to delete")));
    setSelected([]);
  };

  const handleArchiveProjects = () => {
    for (const p of selected)
      updateProject({ id: p, patch: { state: ProjectState.ARCHIVED } })
        .unwrap()
        .catch((err) => showAlert(t("Failed to archive")));
    setSelected([]);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const getOrganizationName = (org) => {
    return organizations ? organizations.find((o) => o.id === org)?.name : null;
  };

  const OrganizationLink = ({ id }) => {
    const name = getOrganizationName(id);
    return name ? <OrganizationLinkLabel id={id} name={name} /> : t("(none)");
  };

  const handleFilterChange = (newFilters) => {
    const newSearchParams = newFilters.toQueryString();
    newSearchParams.set("rowsPerPage", rowsPerPage);
    setSearchParams(newSearchParams, { replace: true });
    setFilters(newFilters);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <ProjectListToolbar
        organization={organization}
        selectedProjectIds={selected}
        onDeleteProject={handleDeleteProject}
        onArchiveProjects={handleArchiveProjects}
        filters={filters}
        setFilters={handleFilterChange}
      />
      <TableContainer>
        <Table
          sx={{ minWidth: 750, tableLayout: "fixed" }}
          aria-labelledby="tableTitle"
          size="medium"
        >
          <ProjectListHeader
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            columns={headCells.filter(
              (c) => (c.id !== "owner" && c.id !== "feedback") || extendedView
            )}
            filters={filters}
            setFilters={handleFilterChange}
          />
          <TableBody>
            {rows
              .slice()
              .sort(getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const isItemSelected = isSelected(row.id);

                return (
                  <TableRow
                    hover
                    onClick={(event) => handleClick(event, row.id)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox color="primary" checked={isItemSelected} />
                    </TableCell>
                    <TableCell padding="none">
                      <Link to={`${row.id}`}>
                        {row.refNumber || "(not set)"}
                      </Link>
                    </TableCell>
                    {extendedView && (
                      <TableCell align="right">
                        <OrganizationLink id={row.ownerOrganization} />
                      </TableCell>
                    )}
                    <TableCell align="right">
                      {row.teethUnderRepair
                        .map((t) => getToothLabel(t, notation))
                        .join(", ")}
                    </TableCell>
                    <TableCell align="right">
                      <ProjectStateLabel
                        state={row.hasError() ? ProjectState.ERROR : row.state}
                      />
                    </TableCell>
                    {extendedView && (
                      <TableCell align="right">
                        <FeedbackLabel value={row.feedback} compact={true} />
                      </TableCell>
                    )}
                    <TableCell align="right">
                      <Timestamp time={row.createdAt} />
                      {!user && (
                        <>
                          {" "}
                          {t("by")}{" "}
                          <Typography
                            variant="inherit"
                            display={"inline-block"}
                            noWrap
                            maxWidth={"15ch"}
                            sx={{ verticalAlign: "top" }}
                          >
                            <UserLabel id={row.ownerUser} />
                          </Typography>
                        </>
                      )}
                    </TableCell>
                    <TableCell align="right">
                      <Timestamp time={row.updatedAt} />
                    </TableCell>
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: 53 * emptyRows,
                }}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        className="ui-tablePagination"
      />
    </Box>
  );
}
