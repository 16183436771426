import {Button} from '@mui/material';
import {useCallback, useEffect, useState} from 'react';
import {FakeProgressBar} from '../../widgets/FakeProgressBar';
import {GenerationFailedMessage} from '../../widgets/GenerationFailedMessage';
import {FileFacade, MarginLineEditor} from '@intellident-ai/margin-line-editor';
import '@intellident-ai/margin-line-editor/dist/index.css';
import {useProfile} from '../../../providers/UserProfileProvider';
import {mediastoreUrl, uploadFile} from '../../../services/FileService';

export default function ToothMarginLine({projectId, restoration, arch}) {
  const [isEditable, setIsEditable] = useState(false);
  const [files, setFiles] = useState({arch: null, marginLine: null, splineParameters: null});
  const [errors, setErrors] = useState({arch: false, marginLine: false, splineParameters: false});
  const {user} = useProfile();
  const status = restoration.marginLine?.status;

  const fetchFile = useCallback(async (url, key) => {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${user.access_token}`,
      },
    });

    if (response.ok) {
      const file = await FileFacade.fromBody(response);

      setFiles((state) => ({...state, [key]: file}));
    } else {
      setErrors((state) => ({...state, [key]: true}));
    }
  }, []);

  const isEditorRenderable = useCallback(() => {
    return (status === 'success' && files.arch && files.marginLine && files.splineParameters) || isEditable;
  }, [status, files, isEditable]);

  const isErrorMessageRenderable = useCallback(() => {
    const hasError = errors.arch || errors.marginLine || errors.splineParameters;

    return (status === 'failed' || hasError) && !isEditable;
  }, [status, errors, isEditable]);

  useEffect(() => {
    (async () => {
      if (status !== 'success') return;

      if (files.arch === null) {
        const url = restoration.dieFile.isSet()
          ? mediastoreUrl(projectId, restoration.dieFile.decimatedFileName())
          : mediastoreUrl(projectId, arch.file.decimatedFileName());

        await fetchFile(url, 'arch');
      }

      if (files.marginLine === null) {
        await fetchFile(mediastoreUrl(projectId, restoration.marginLineFileName()), 'marginLine');
      }

      if (files.splineParameters === null) {
        await fetchFile(mediastoreUrl(projectId, restoration.splineParametersFileName()), 'splineParameters');
      }
    })();
  }, [restoration]);

  const saveMarginLine = useCallback(async (output) => {
    const jsonBlob = new Blob([JSON.stringify(output.bsplineCurve)], {type: 'application/json'});
    const jsonFile = new File([jsonBlob], restoration.splineParametersFileName());
    const plyBlob = new Blob([output.pointCloud], {type: 'application/octet-stream'});
    const plyFile = new File([plyBlob], restoration.marginLineFileName());

    await Promise.all([
      uploadFile(projectId, jsonFile.name, jsonFile, user.access_token),
      uploadFile(projectId, plyFile.name, plyFile, user.access_token),
    ])
      .then(() => {
        const splineParameters = FileFacade.fromBlob(jsonBlob);
        const marginLine = FileFacade.fromBlob(plyBlob);

        setFiles((state) => ({...state, splineParameters, marginLine}));
      })
      .catch((error) => console.error(error));
  }, []);

  if (isEditorRenderable()) {
    return (
      <MarginLineEditor
        key={restoration.tooth}
        height='500px'
        archFile={files.arch}
        marginLineFile={files.marginLine}
        splineParametersFile={files.splineParameters}
        editable={isEditable}
        onMarginLineError={(error) => console.log('onMarginLineError ', error)}
        onSplineParametersError={(error) => console.log('onSplineParametersError ', error)}
        onSaveMarginLine={saveMarginLine}
      />
    );
  } else if (isErrorMessageRenderable()) {
    return (
      <GenerationFailedMessage
        actions={(
          <Button variant="contained" onClick={() => setIsEditable(true)}>
            Create margin line manually
          </Button>
        )}
      />
    );
  }

  return <FakeProgressBar />;
}
