import File from "./File";

export default class ToothRestoration {
  constructor(obj) {
    // arguments validation and coercion
    if (typeof obj === "number") obj = { tooth: obj };
    else if (!obj) throw new Error("tooth number must be provided");
    else if (!obj.tooth) throw new Error("tooth attribute must be provided");
    else if (typeof obj.tooth !== "number")
      throw new Error("tooth must be a number");

    let data = {
      tooth: null,
      dieFile: new File(),
      marginLine: null,
      crownGeneration: null,
      toothTemplate: null,
    };
    Object.defineProperties(this, {
      tooth: {
        get: () => data.tooth,
        enumerable: true,
      },
      dieFile: {
        get: () => data.dieFile,
        enumerable: true,
      },
      marginLine: {
        get: () => data.marginLine,
        enumerable: true,
      },
      crownGeneration: {
        get: () => data.crownGeneration,
        enumerable: true,
      },
      toothTemplate: {
        get: () => data.toothTemplate,
        enumerable: true,
      },
      raw: {
        get: () => ({ ...data, dieFile: data.dieFile.raw }),
      },
    });

    // Handle old/deprecated schema version
    if (obj && "marginLineStatus" in obj && !("marginLine" in obj))
      obj.marginLine = { status: obj.marginLineStatus };
    if (obj && "crownGenerationStatus" in obj && !("crownGeneration" in obj))
      obj.crownGeneration = { status: obj.crownGenerationStatus };

    Object.assign(data, {
      ...obj,
      dieFile: new File({
        ...(obj.dieFile ? obj.dieFile : {}),
        id: obj.tooth,
      }),
    });
  }

  toJSON() {
    return this.raw;
  }

  marginLineFileName() {
    return `marginline_${this.tooth}.ply`;
  }

  splineParametersFileName() {
    return `splineParameters_${this.tooth}.json`;
  }

  crownFileName() {
    return `crownFinal_${this.tooth}.stl`;
  }

  crownShellFileName() {
    return `crownShell_${this.tooth}.stl`;
  }

  crownShellOpenFileName() {
    return `crownShellOpen_${this.tooth}.stl`;
  }

  crownBottomAndMarginlineFileName() {
    return `crownBottomMgl_${this.tooth}.stl`;
  }

  hasMarginLine() {
    return this.marginLine !== null;
  }
}
