import { useMemo, useState } from 'react'
import { Button, Divider, Menu, Slider, Typography, Box } from '@mui/material'
import { useTranslation } from 'react-i18next'

export const MeshViewerContextVisibility = ({ files, context, loadedImages }) => {
  const [slides, setSlides] = useState({})
  const [anchorEl, setAnchorEl] = useState(null)
  const settingsOpen = Boolean(anchorEl)

  const { t } = useTranslation();

  const [visibilityArches, visibilityCrowns] = useMemo(() => {
    const visibleFiles = [...files].filter(file => file.show)

    const upperFile = visibleFiles.find(file => file.name.includes('decimated_upper'))
    const lowerFile = visibleFiles.find(file => file.name.includes('decimated_lower'))
    const crownFiles = visibleFiles.filter(file => file.name.includes('crownFinal'))

    const arches = []

    if (upperFile) {
      arches.push({ name: upperFile.name, label: t('Upper arch') })
      setSlides(prevState => {
        prevState[upperFile.name] = 1

        return prevState
      })
    }

    if (lowerFile) {
      arches.push({ name: lowerFile.name, label: t('Lower arch') })

      setSlides(prevState => {
        prevState[lowerFile.name] = 1

        return prevState
      })
    }

    const crowns = crownFiles.map(({ name, tooth }) => (
      { name, label: `${t('Crown')} ${tooth}` }
    ))

    crowns.forEach(crown => {
      setSlides(prevState => {
        prevState[crown.name] = 1

        return prevState
      })
    })

    return [arches, crowns]
  }, [])

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const onSlide = (name, newValue) => {
    setSlides(prevState => ({
        ...prevState,
        [name]: newValue,
      }),
    )

    loadedImages.current[name]?.getProperty().setOpacity(newValue)

    context.renderWindow.render()
  }

  return (
    <>
      <Box id="context-visibility" sx={{
        position: 'absolute',
        bottom: '1rem',
        left: '1rem',
      }}>
        <Menu
          container={document.getElementById('context-visibility')}
          anchorEl={anchorEl}
          open={settingsOpen}
          onClose={handleMenuClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          sx={{
            '& .MuiPaper-root': {
              width: '250px',
              mt: '-0.5rem',
            },
            '& .MuiSlider-root': {
              verticalAlign: 'top',
            },
          }}
        >
          {visibilityArches.length > 0 && visibilityArches.map((item) => (
            <ContextItem key={item.label} name={item.name} label={item.label} value={slides[item.name]} onSlide={onSlide} />
          ))}
          <Divider component="li" sx={{ m: '0.375rem 0', '&:first-of-type, &:last-of-type': { display: 'none' } }} />
          {visibilityCrowns.length > 0 && visibilityCrowns.map((item) => (
            <ContextItem key={item.label} name={item.name} label={item.label} value={slides[item.name]} onSlide={onSlide} />
          ))}
        </Menu>
        <Button
          variant="contained"
          onClick={handleMenuClick}
        >
          {t('Context visibility')}
        </Button>
      </Box>
    </>
  )
}

const ContextItem = ({ label, name, value, onSlide }) => {
  const handleDoubleClick = () => {
    if (value === 0) {
      onSlide(name, 1)

      return
    }

    onSlide(name, 0)
  }

  return (
    <Box component="li" sx={{
      display: 'flex',
      alignItems: 'center',
      gap: '0.5rem',
      p: '0.25rem 1rem',
      minHeight: '2.25rem',
    }}>
      <Typography variant="body2" sx={{
        width: '40%',
        cursor: 'pointer',
        userSelect: 'none',
      }} onDoubleClick={handleDoubleClick}>
        {label}
      </Typography>
      <Box sx={{ flex: 1 }}>
        <Slider
          value={value}
          step={0.01}
          size="small"
          onChange={(event, newValue) => {
            onSlide(name, newValue)
          }}
          min={0}
          max={1}
        />
      </Box>
    </Box>
  )
}
