export default {
  appUrl: process.env.REACT_APP_URL,
  backendUrl: process.env.REACT_APP_BACKEND_URL,
  reCaptchaSitekey: process.env.REACT_APP_RECAPTCHA_SITE_KEY,
  adminEmail: process.env.REACT_APP_ADMIN_EMAIL,
  prepEvaluationUrl: process.env.REACT_APP_PREP_EVALUATION_URL,
  crownEvaluationUrl: process.env.REACT_APP_CROWN_EVALUATION_URL,
  colors: {
    viewer: {
      background: [51, 77, 102],
      marginLine: [166, 204, 0],
      crown: [201, 232, 255],
    },
  },
  colorMaps: {
    upper: [
      [0, 255, 162, 143],
      [1, 0, 129, 129],
      [2, 144, 40, 167],
      [3, 210, 119, 0],
      [4, 28, 72, 208],
      [5, 224, 74, 76],
      [6, 220, 171, 0],
      [7, 126, 126, 126],
      [8, 204, 204, 204],
      [9, 255, 224, 73],
      [10, 252, 100, 135],
      [11, 113, 146, 233],
      [12, 255, 169, 25],
      [13, 209, 135, 205],
      [14, 66, 148, 145],
      [15, 190, 213, 88],
      [16, 133, 140, 0],
      [17, 0, 51, 0],
      [18, 102, 102, 255],
      [19, 204, 204, 0],
      [20, 102, 0, 51],
      [21, 231, 232, 228],
      [22, 95, 95, 95],
      [23, 102, 0, 204],
      [24, 255, 153, 0],
    ],
    lower: [
      [0, 255, 162, 143],
      [1, 170, 255, 255],
      [2, 255, 0, 127],
      [3, 170, 255, 127],
      [4, 0, 0, 127],
      [5, 255, 255, 127],
      [6, 255, 170, 255],
      [7, 255, 255, 255],
      [8, 75, 75, 75],
      [9, 255, 0, 255],
      [10, 255, 255, 0],
      [11, 0, 0, 255],
      [12, 0, 255, 0],
      [13, 255, 0, 0],
      [14, 0, 255, 255],
      [15, 68, 114, 196],
      [16, 255, 80, 80],
    ],
  },
};
