import { Typography } from "@mui/material";
import { useSettings } from "../../providers/UserProfileProvider";
import { getToothLabel } from "../../utils/arch";

const ArchRange = ({ arch }) => {
  const { notation } = useSettings();

  return (
    <Typography
      sx={{
        color: "text.secondary",
        width: "80px",
        flexShrink: 0,
        textAlign: "right",
        marginRight: "10px",
        visibility: arch.isReady() ? "visible" : "hidden",
      }}
    >
      {getToothLabel(arch.start, notation)} &#8211;{" "}
      {getToothLabel(arch.end, notation)}
    </Typography>
  );
};

export default ArchRange;
