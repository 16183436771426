import React from "react";
import {Popover, Typography, Button, Box} from "@mui/material";
import {useTranslation} from "react-i18next";
import Divider from '@mui/material/Divider';

const trimmedTextStyle = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

const ProjectDetailsPopover = ({
   project: {projectName, refNumber, doctorName, patientName},
   isOpen,
   onClose,
   anchorEl,
   onEditClick
 }) => {
  const {t} = useTranslation();

  return (
    <Popover
      open={isOpen}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={{vertical: "bottom", horizontal: "center"}}
      transformOrigin={{vertical: "top", horizontal: "center"}}
    >
      <Box p={2} width={500}>
        <Typography variant="h6" gutterBottom>
          {t("Project details")}
        </Typography>

        <Typography variant="caption">{t("Project name")}</Typography>
        <Typography style={trimmedTextStyle} gutterBottom>{projectName ?? t("Dental Design Project")}</Typography>
        <Divider/>

        <Typography variant="caption">{t("Reference #")}</Typography>
        <Typography style={trimmedTextStyle} gutterBottom>{refNumber}</Typography>
        <Divider/>

        <Typography variant="caption">{t("Doctor")}</Typography>
        <Typography style={trimmedTextStyle} gutterBottom>{doctorName || "-"}</Typography>
        <Divider/>

        <Typography variant="caption">{t("Patient")}</Typography>
        <Typography style={trimmedTextStyle} gutterBottom>{patientName || "-"}</Typography>

        <Button onClick={onEditClick} color="primary" fullWidth variant="outlined">
          {t("Edit")}
        </Button>
      </Box>
    </Popover>
  );
};

export default ProjectDetailsPopover;
