import React, {useEffect} from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";

const trimmedStringWithKeyboardSymbolsMaxLength200RegexPattern = /^(?!\s*$)[\S ]{1,200}$/;

export default function ProjectDialog({ isOpen, onSubmit, onClose, project }) {
  const { t } = useTranslation();

  const isEditMode = Boolean(project);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid, dirtyFields },
    reset
  } = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    if (project) {
      reset({
        projectName: project.projectName ?? t("Dental Design Project"),
        refNumber: project.refNumber || "",
        doctorName: project.doctorName || "",
        patientName: project.patientName || "",
      });
    }
  }, [project, reset, t]);

  const isFormDirty = Object.keys(dirtyFields).length > 0;

  return (
      <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="sm">
        <DialogTitle align={"center"}>{isEditMode ? t("Edit Project details") : t("New Project")}</DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            {isEditMode && (
              <TextField
                  label={t("Project name")}
                  variant="outlined"
                  fullWidth
                  {...register("projectName", {
                    required: "Project name is required",
                    pattern: {
                      value: trimmedStringWithKeyboardSymbolsMaxLength200RegexPattern,
                      message: "Invalid Project name format",
                    },
                  })}
                  error={!!errors.projectName}
                  helperText={errors.projectName?.message}
                  margin="dense"
              />
            )}
            <TextField
                label={t("Reference #")}
                variant="outlined"
                fullWidth
                {...register("refNumber", {
                  required: "Reference Number is required",
                  pattern: {
                    value: trimmedStringWithKeyboardSymbolsMaxLength200RegexPattern,
                    message: "Invalid Reference Number format",
                  },
                })}
                error={!!errors.refNumber}
                helperText={errors.refNumber?.message}
                margin="dense"
            />
            <TextField
                label={`${t("Doctor's name")} (${t("optional")})`}
                variant="outlined"
                fullWidth
                {...register("doctorName", {
                  pattern: {
                    value: trimmedStringWithKeyboardSymbolsMaxLength200RegexPattern,
                    message: "Invalid Doctor's Name format",
                  },
                })}
                error={!!errors.doctorName}
                helperText={errors.doctorName?.message}
                margin="dense"
            />
            <TextField
                label={`${t("Patient's name")} (${t("optional")})`}
                variant="outlined"
                fullWidth
                {...register("patientName", {
                  pattern: {
                    value: trimmedStringWithKeyboardSymbolsMaxLength200RegexPattern,
                    message: "Invalid Patient's Name format",
                  },
                })}
                error={!!errors.patientName}
                helperText={errors.patientName?.message}
                margin="dense"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} variant="outlined">
              {t("Cancel")}
            </Button>
            <Button
                type="submit"
                variant="contained"
                disabled={!isValid || !isFormDirty}
            >
              {isEditMode ? t("Save") : t("Continue")}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
  );
}
