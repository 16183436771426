// Load the rendering pieces we want to use (for both WebGL and WebGPU)
import '@kitware/vtk.js/Rendering/Profiles/Molecule' // vtkSphereMapper
import vtkInteractorStyleTrackballCamera from '@kitware/vtk.js/Interaction/Style/InteractorStyleTrackballCamera'
import vtkPLYReader from '@kitware/vtk.js/IO/Geometry/PLYReader'
import vtkSTLReader from '@kitware/vtk.js/IO/Geometry/STLReader'
import vtkOBJReader from '@kitware/vtk.js/IO/Misc/OBJReader'
import vtkXMLPolyDataReader from '@kitware/vtk.js/IO/XML/XMLPolyDataReader'
import vtkActor from '@kitware/vtk.js/Rendering/Core/Actor'
import vtkColorTransferFunction from '@kitware/vtk.js/Rendering/Core/ColorTransferFunction'
import vtkMapper from '@kitware/vtk.js/Rendering/Core/Mapper'
import { ColorMode, ScalarMode } from '@kitware/vtk.js/Rendering/Core/Mapper/Constants'
import vtkSphereMapper from '@kitware/vtk.js/Rendering/Core/SphereMapper'
import vtkFullScreenRenderWindow from '@kitware/vtk.js/Rendering/Misc/FullScreenRenderWindow'
import '@kitware/vtk.js/Rendering/Profiles/Geometry'
import { Fullscreen } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import axios from 'axios'
import { useEffect, useRef, useState } from 'react'
import config from '../../config'
import { useAuth } from '../../providers/authProvider'
import { useSnackbar } from '../../providers/SnackbarProvider'
import { useTranslation } from 'react-i18next'
import { MeshViewerContextVisibility } from './MeshViewerContextVisibility'

function openFullscreen(e) {
  if (e.requestFullscreen) {
    e.requestFullscreen();
  } else if (e.webkitRequestFullscreen) {
    /* Safari */
    e.webkitRequestFullscreen();
  } else if (e.msRequestFullscreen) {
    /* IE11 */
    e.msRequestFullscreen();
  }
}

function closeFullscreen() {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if (document.webkitExitFullscreen) {
    /* Safari */
    document.webkitExitFullscreen();
  } else if (document.msExitFullscreen) {
    /* IE11 */
    document.msExitFullscreen();
  }
}

export default function MeshViewer({ width, height, files, contextVisibility }) {
  const vtkContainerRef = useRef(null);
  const [context, setContext] = useState(null);
  const loadedImages = useRef({});
  const loading = useRef([]);
  const auth = useAuth();
  const { showAlert } = useSnackbar();
  const { t } = useTranslation();

  function toggleFullScreen() {
    const fullScreen = vtkContainerRef.current.clientWidth == screen.width;

    if (fullScreen) closeFullscreen();
    else openFullscreen(vtkContainerRef.current);
  }

  // asduiash
  useEffect(() => {
    const fullScreenRenderWindow = vtkFullScreenRenderWindow.newInstance({
      background: config.colors.viewer.background.map((x) => x / 255.0),
      rootContainer: vtkContainerRef.current,
      containerStyle: {
        height: height
      },
    });
    const renderer = fullScreenRenderWindow.getRenderer();
    const renderWindow = fullScreenRenderWindow.getRenderWindow();

    // Camera manipulations
    fullScreenRenderWindow
      .getInteractor()
      .setInteractorStyle(vtkInteractorStyleTrackballCamera.newInstance());

    // ColorMap
    const lookupTable = vtkColorTransferFunction.newInstance();
    config.colorMaps.upper.forEach((entry) =>
      lookupTable.addRGBPoint(
        entry[0],
        entry[1] / 255.0,
        entry[2] / 255.0,
        entry[3] / 255.0
      )
    );
    lookupTable.build();

    // Provide a better default camera initial position
    const camera = renderer.getActiveCamera();
    camera.azimuth(180);
    camera.elevation(90);
    camera.orthogonalizeViewUp();

    const container = fullScreenRenderWindow.getContainer();
    container.style.height = "100%";
    container.ondblclick = toggleFullScreen;

    setContext({
      renderWindow,
      renderer,
      fullScreenRenderWindow,
      lookupTable,
    });

    return () => {
      fullScreenRenderWindow.delete();
      renderWindow.delete();
      setContext(null);
    };
  }, [vtkContainerRef]);

  function loadVtpFile(fileContent) {
    const { lookupTable } = context;

    const reader = vtkXMLPolyDataReader.newInstance();
    reader.parseAsArrayBuffer(fileContent);
    const source = reader.getOutputData(0);

    const mapper = vtkMapper.newInstance({
      useLookupTableScalarRange: true,
      lookupTable,
      colorByArrayName: "Label",
      colorMode: ColorMode.MAP_SCALARS,
      interpolateScalarsBeforeMapping: false,
      scalarMode: ScalarMode.USE_CELL_FIELD_DATA,
      scalarVisibility: true,
    });
    mapper.setInputData(source);

    return vtkActor.newInstance({ mapper });
  }

  function createActor(source) {
    // if file defines no poly, then map each point to a sphere
    const mapper =
      source.getNumberOfPolys() == 0
        ? vtkSphereMapper.newInstance({
            scalarVisibility: false,
            radius: 0.04,
          })
        : // otherwise, use the default mapper
          vtkMapper.newInstance({ scalarVisibility: true });

    mapper.setInputData(source);
    return vtkActor.newInstance({ mapper });
  }

  function loadPlyFile(fileContent) {
    const reader = vtkPLYReader.newInstance();
    reader.parseAsArrayBuffer(fileContent);
    const source = reader.getOutputData(0);
    return createActor(source);
  }

  function loadStlFile(fileContent) {
    const reader = vtkSTLReader.newInstance();
    reader.parseAsArrayBuffer(fileContent);
    const source = reader.getOutputData(0);
    const mapper = vtkMapper.newInstance({ scalarVisibility: false });
    mapper.setInputData(source);
    return vtkActor.newInstance({ mapper });
  }

  function loadObjFile(fileContent) {
    const reader = vtkOBJReader.newInstance();
    reader.parseAsText(fileContent);
    const source = reader.getOutputData(0);
    return createActor(source);
  }

  function loadActor(actor, isVisible, color, opacity) {
    const { renderer, renderWindow } = context;

    if (color) {
      actor.getProperty().setColor(...color.map((c) => c / 255));
    }

    if (opacity) {
      actor.getProperty().setOpacity(opacity);
    }

    actor.setVisibility(isVisible);
    renderer.addActor(actor);
    renderer.resetCamera();
    renderWindow.render();
    return actor;
  }

  function getFileLoader(file) {
    file = file.toLowerCase();
    if (file.endsWith(".stl")) return loadStlFile;
    else if (file.endsWith(".vtp")) return loadVtpFile;
    else if (file.endsWith(".ply")) return loadPlyFile;
    else if (file.endsWith(".obj")) return loadObjFile;
    else throw new Error("Unsupported file type");
  }

  async function loadFile(file) {
    const user = await auth.getUser();

    const loader = getFileLoader(file.name);

    loading.current = [...loading.current, file.name];
    return axios
      .get(file.name, {
        responseType: loader === loadObjFile ? "text" : "arraybuffer",
        headers: { Authorization: `Bearer ${user.access_token}` },
      })
      .then((response) => {
        const actor = loadActor(loader(response.data), file.show, file.color);
        loadedImages.current[file.name] = actor;
      })
      .catch((e) => {
        console.error(e);
        showAlert(t("Failed to load file"));
        loadedImages.current[file.name] = null;
      })
      .finally(
        () => (loading.current = loading.current.filter((x) => x != file.name))
      );
  }

  useEffect(() => {
    if (context) {
      // cleanup unwanted/deprecated images
      Object.entries(loadedImages.current).forEach(([file, actor]) => {
        if (!files.map((f) => f.name).includes(file)) {
          if (actor) {
            context.renderer.removeActor(actor);
            actor.delete();
          }
          delete loadedImages.current[file];
        }
      });

      Array.isArray(files) && files.forEach((file) => {
        if (file.name in loadedImages.current) {
          // update existing images' visibility
          const actor = loadedImages.current[file.name];
          if (actor) actor.setVisibility(file.show);
        } else if (file.show && !loading.current.includes(file.name)) {
          // load new, visible images (that are not already currently being loaded)
          loadFile(file);
        }
      });

      // refresh display
      if (!context.renderWindow.isDeleted()) {
        context.renderWindow.render();
      }
    }
  }, [context, files]);

  return (
    <div
      style={{
        width: width,
        height: height,
        position: "relative",
        margin: "auto",
      }}
      ref={vtkContainerRef}
    >
      {contextVisibility && <MeshViewerContextVisibility context={context} files={files} loadedImages={loadedImages} />}
      <IconButton
        color="primary"
        sx={{ position: "absolute", right: 0, bottom: 0 }}
        onClick={toggleFullScreen}
        className="ui-preview-toggle-fullscreen"
      >
        <Fullscreen />
      </IconButton>
    </div>
  )
}
