import config from "../../config";
import { useProjectApi } from "../../providers/ProjectApiProvider";
import { mediastoreUrl } from "../../services/FileService";
import MeshViewer from "../widgets/MeshViewer";

export default function ProjectFilesViewer({ isVisible }) {
  const {
    project: { id: projectId, upperArch, lowerArch, toothRestorations },
  } = useProjectApi();

  function buildFilesToShow() {
    return [].concat(
      [
        {
          name: mediastoreUrl(projectId, upperArch.file.decimatedFileName()),
          show: isVisible("upperArch"),
        },
        {
          name: mediastoreUrl(projectId, lowerArch.file.decimatedFileName()),
          show: isVisible("lowerArch"),
        },
      ],
      Object.values(toothRestorations).map((t) => ({
        name: mediastoreUrl(projectId, t.dieFile.decimatedFileName()),
        show: isVisible(`die${t.tooth}`),
        tooth: t.tooth,
      })),
      ...Object.values(toothRestorations).map((t) => [
        {
          name: mediastoreUrl(projectId, t.crownFileName()),
          show:
            isVisible(`crown${t.tooth}`) &&
            t.crownGeneration?.status === "success",
          tooth: t.tooth,
          color: config.colors.viewer.crown,
        },
        {
          name: mediastoreUrl(projectId, t.crownShellFileName()),
          show:
            isVisible(`crownShell${t.tooth}`) &&
            t.crownGeneration?.status === "success",
          tooth: t.tooth,
        },
        {
          name: mediastoreUrl(projectId, t.crownShellOpenFileName()),
          show:
            isVisible(`crownShellOpen${t.tooth}`) &&
            t.crownGeneration?.status === "success",
          tooth: t.tooth,
        },
        {
          name: mediastoreUrl(projectId, t.crownBottomAndMarginlineFileName()),
          show:
            isVisible(`crownWithCrownBottom${t.tooth}`) &&
            t.crownGeneration?.status === "success",
          tooth: t.tooth,
        },
        {
          name: mediastoreUrl(projectId, t.marginLineFileName()),
          show:
            isVisible(`marginLine${t.tooth}`) &&
            t.marginLine?.status === "success",
          color: config.colors.viewer.marginLine,
          tooth: t.tooth,
        },
        // {
        //   name: mediastoreUrl(projectId, t.splineParametersFileName()),
        //   show:
        //     isVisible(`marginLine${t.tooth}`) &&
        //     t.marginLine?.status === "success",
        //   color: config.colors.viewer.marginLine,
        // },
      ])
    );
  }

  return <MeshViewer width="100%" height="480px" files={buildFilesToShow()} contextVisibility={true} />
}
