import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import MuiStep from "@mui/material/Step";
import Stepper from "@mui/material/Stepper";
import Typography from "@mui/material/Typography";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ProjectState from "../../model/ProjectState";
import { useConfirmationDialog } from "../../providers/ConfirmationDialogProvider";
import { useProjectApi } from "../../providers/ProjectApiProvider";
import {
  useSettings,
  useUpdateSettings,
} from "../../providers/UserProfileProvider";
import ArchScans from "./ArchScans";
import MarginLine from "./MarginLine";
import PrepEvaluation from "./PrepEvaluation";
import CrownEvaluation from "./CrownEvaluation";
import CompletedProject from "./CompletedProject";
import CrownGeneration from "./CrownGeneration";
import TeethMapping from "./TeethMapping";
import ProjectMenu from "./ProjectMenu";
import { StepButton, IconButton } from "@mui/material";
import ProjectDetailsPopover from "../projects/ProjectDetailsPopover";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ProjectDialog from "./ProjectDialog";
import {replaceEmptyStringsWithNull} from "../../utils/ReplaceEmptyStringsWithNull";

class Step {
  static TEETH_MAPPING = new Step(0, "Teeth Mapping");
  static SCANS = new Step(1, "Scans");
  static MARGIN_LINE = new Step(2, "Margin Line");
  static PREP_EVALUATION = new Step(3, "Prep Evaluation");
  static CROWN_GENERATION = new Step(4, "Crown Generation");
  static CROWN_EVALUATION = new Step(5, "Crown Evaluation");
  static CONCLUSION = new Step(6, "Download");
  static {
    this.TEETH_MAPPING.next = this.SCANS;

    this.SCANS.next = this.MARGIN_LINE;
    this.SCANS.previous = this.TEETH_MAPPING;

    this.MARGIN_LINE.next = this.PREP_EVALUATION;
    this.MARGIN_LINE.previous = this.SCANS;

    this.PREP_EVALUATION.next = this.CROWN_GENERATION;
    this.PREP_EVALUATION.previous = this.MARGIN_LINE;

    this.CROWN_GENERATION.next = this.CROWN_EVALUATION;
    this.CROWN_GENERATION.previous = this.PREP_EVALUATION;

    this.CROWN_EVALUATION.next = this.CONCLUSION;
    this.CROWN_EVALUATION.previous = this.CROWN_GENERATION;

    this.CONCLUSION.previous = this.CROWN_EVALUATION;
  }

  constructor(value, name) {
    this.value = value;
    this.name = name;
  }

  nextButtonDisabled(project) {
    return (
      (this === Step.TEETH_MAPPING && !project.isReadyForScans()) ||
      (this === Step.SCANS && !project.isReadyForMarginLineGeneration()) ||
      (this === Step.MARGIN_LINE && !project.isReadyForPrepEvaluation()) ||
      (this === Step.CROWN_GENERATION &&
        Object.values(project.toothRestorations).some(
          (x) => x.crownGeneration?.status !== "success"
        )) ||
      !project.dataUsageConsent
    );
  }
}

const getPageForState = (state) => {
  const stateToStepMap = {
    [ProjectState.NEW.value]: Step.TEETH_MAPPING,
    [ProjectState.SCANS.value]: Step.SCANS,
    [ProjectState.MARGIN_LINE.value]: Step.MARGIN_LINE,
    [ProjectState.PREP_EVALUATION.value]: Step.PREP_EVALUATION,
    [ProjectState.CROWN_GENERATION.value]: Step.CROWN_GENERATION,
    [ProjectState.CROWN_EVALUATION.value]: Step.CROWN_EVALUATION,
    [ProjectState.COMPLETE.value]: Step.CONCLUSION,
  };

  if (state.value in stateToStepMap) {
    return stateToStepMap[state.value];
  }

  throw Error("unexpected/invalid project state");
}

export default function ProjectWorkflow() {
  const navigate = useNavigate();
  const { project, saveProject } = useProjectApi();
  const { showConfirmationDialog } = useConfirmationDialog();
  const { t } = useTranslation();
  const { disableFeedbackPrompt } = useSettings();
  const updateSettings = useUpdateSettings();
  const projectStep = getPageForState(project.state);
  const [activeStep, setActiveStep] = useState(projectStep);
  const [errorOnPage, setErrorOnPage] = useState(false);
  const projectDetailsPopoverRef = useRef(null);
  const [isProjectDetailsPopoverOpen, setIsProjectDetailsPopoverOpen] = useState(false);
  const [isEditProjectDialogOpen, setIsEditProjectDialogOpen] = useState(false);

  function nextButtonCaption(step) {
    if (step.value === Step.TEETH_MAPPING.value) {
      return t('Scans');
    } else if (step.value === Step.SCANS.value) {
      return t('Margin Line Detection');
    } else if (step.value === Step.MARGIN_LINE.value) {
      return t('Prep Evaluation');
    } else if (step.value === Step.PREP_EVALUATION.value) {
      return t('Crown Generation');
    } else if (step.value === Step.CROWN_GENERATION.value) {
      return t('Crown Evaluation');
    } else if (step.value === Step.CROWN_EVALUATION.value) {
      return t('Download Crowns');
    } else if (step.value === Step.CONCLUSION.value) {
      return t('Done');
    }

    throw new Error('Unknown step');
  }

  function getStepContent(step) {
    if (step.value === Step.TEETH_MAPPING.value) {
      return <TeethMapping setErrorOnPage={setErrorOnPage} />;
    } else if (step.value === Step.SCANS.value) {
      return <ArchScans />;
    } else if (step.value === Step.MARGIN_LINE.value) {
      return <MarginLine />;
    } else if (step.value === Step.PREP_EVALUATION.value) {
      return <PrepEvaluation />;
    } else if (step.value === Step.CROWN_GENERATION.value) {
      return <CrownGeneration />;
    } else if (step.value === Step.CROWN_EVALUATION.value) {
      return <CrownEvaluation />;
    } else if (step.value === Step.CONCLUSION.value) {
      return <CompletedProject />;
    }

    throw new Error('Unknown step');
  }

  const transitionProject = (newState) =>
    saveProject({
      state: newState,
    });

  const confirmNoAntagonist = () =>
    showConfirmationDialog({
      title: t("projects.actions.generateWithoutAntagonist.title"),
      message: (
        <>
          <p>{t("projects.actions.generateWithoutAntagonist.paragraph1")}</p>
          <p>{t("projects.actions.generateWithoutAntagonist.paragraph2")}</p>
        </>
      ),
      canDismiss: false,
    });

  const confirmNoFeedback = () =>
    showConfirmationDialog({
      title: t("projects.feedbackRequest"),
      message: (
        <>
          <p>
            {t("projects.actions.completeProjectWithoutFeedback.paragraph1")}
          </p>
          <p>
            {t("projects.actions.completeProjectWithoutFeedback.paragraph2")}
          </p>
        </>
      ),
      canDismiss: false,
      buttons: [t("Proceed"), t("Cancel")],
      promptDoNotShowAgain: true,
      onDoNotShowAgain: (newValue) => {
        updateSettings({ disableFeedbackPrompt: newValue });
      },
    });

  const handleNext = async () => {
    if (activeStep === Step.TEETH_MAPPING && project.state === ProjectState.NEW) {
      transitionProject(ProjectState.SCANS);
    } else if (
      activeStep === Step.SCANS &&
      project.state === ProjectState.SCANS
    ) {
      if (
        (!project.upperArch.isBlank() && !project.lowerArch.isBlank()) ||
        (await confirmNoAntagonist())
      ) {
        // transitionProject(ProjectState.CROWN_GENERATION);
        transitionProject(ProjectState.MARGIN_LINE);
      } else {
        return;
      }
    } else if (
      activeStep === Step.MARGIN_LINE &&
      project.state === ProjectState.MARGIN_LINE &&
      project.isReadyForPrepEvaluation()
    ) {
      transitionProject(ProjectState.PREP_EVALUATION);
    } else if (
      activeStep === Step.PREP_EVALUATION &&
      project.state === ProjectState.PREP_EVALUATION
    ) {
      transitionProject(ProjectState.CROWN_GENERATION);
    } else if (
      activeStep === Step.CROWN_GENERATION &&
      project.state === ProjectState.CROWN_GENERATION
    ) {
      // transitionProject(ProjectState.COMPLETE);
      transitionProject(ProjectState.CROWN_EVALUATION);
    } else if (
      activeStep === Step.CROWN_EVALUATION &&
      project.state === ProjectState.CROWN_EVALUATION
    ) {
      transitionProject(ProjectState.COMPLETE);
    } else if (activeStep === Step.CONCLUSION) {
      if (
        !!project.feedback ||
        disableFeedbackPrompt ||
        (await confirmNoFeedback())
      ) {
        navigate("/projects");
      }

      return;
    }
    setActiveStep(activeStep.next);
  };

  const handleBack = () => {
    setActiveStep(activeStep.previous);
  };

  const handleProjectDetailsPopoverClick = () => { setIsProjectDetailsPopoverOpen(true)}
  return (
    <>
      <Box display="flex" alignItems="center" width="100%">
        <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexGrow={1}
            sx={{
              overflow: 'hidden',
            }}
        >
          <Typography
              component="h1"
              variant="h5"
              align="center"
              ref={projectDetailsPopoverRef}
              sx={{
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              }}
          >
            {project.projectName ?? t("Dental Design Project")}
          </Typography>
          <IconButton onClick={handleProjectDetailsPopoverClick}>
            <ArrowDropDownIcon />
          </IconButton>
        </Box>
        <ProjectMenu />
      </Box>
      <Stepper nonLinear activeStep={activeStep.value} sx={{ pt: 3, pb: 5 }}>
        {Object.values(Step).map((step) => (
          <MuiStep completed={step.value < projectStep.value} key={step.value}>
            <StepButton
              disabled={step.value > projectStep.value}
              onClick={() => setActiveStep(step)}
            >
              {t(step.name)}
            </StepButton>
          </MuiStep>
        ))}
      </Stepper>
      {getStepContent(activeStep)}
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        {activeStep !== Step.TEETH_MAPPING && (
          <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
            {t("Back")}
          </Button>
        )}

        <Button
          variant="contained"
          onClick={handleNext}
          disabled={errorOnPage || activeStep.nextButtonDisabled(project)}
          sx={{ mt: 3, ml: 1 }}
          className="ui-workflow-next-button"
        >
          {nextButtonCaption(activeStep)}
        </Button>
      </Box>
      <ProjectDetailsPopover project={project} isOpen={isProjectDetailsPopoverOpen} onClose={()=>setIsProjectDetailsPopoverOpen(false)} anchorEl={projectDetailsPopoverRef.current} onEditClick={() => {setIsEditProjectDialogOpen(true); setIsProjectDetailsPopoverOpen(false)}} />
      <ProjectDialog project={project} isOpen={isEditProjectDialogOpen} onClose={() => {setIsEditProjectDialogOpen(false)}} onSubmit={(project) => {saveProject(replaceEmptyStringsWithNull(project)); setIsEditProjectDialogOpen(false)}}/>
    </>
  );
}
