import { Login } from "@mui/icons-material";
import { Box, Button, Dialog, DialogContent, DialogTitle, MenuItem, TextField, Typography } from "@mui/material";
import React, {useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { registerNewUser } from "../services/apiService";
import ReCAPTCHA from "react-google-recaptcha";
import "./Root.css";
import config from "../config";
import { useForm } from "react-hook-form";
import {useGetUserOrganizationQuery} from "../services/userprofile";
import Loading from "../Components/Loading";
import {clearEmptyObjectValues} from "../utils/ClearEmptyObjectValues";

const positions = [
  "Dental clinic / Dentist",
  "Dental laboratory / Technician",
  "Researcher",
  "Other"
];

function WelcomeDialog({ open }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [captchaResponse, setCaptchaResponse] = useState(null);
  const { register, unregister, setValue, handleSubmit, watch, formState: { errors, isValid } } = useForm({
    defaultValues: { otherPositionTitle: '' }
  });
  const selectedPosition = watch("position");
  const { data, error, isLoading } = useGetUserOrganizationQuery();

  useEffect(() => {
    if (selectedPosition === "Other") {
      register("otherPositionTitle", {
        required: t("This field is required."),
        maxLength: { value: 200, message: t("Maximum limit is {{value}} characters.", { value: 200 }) },
        pattern: { value: /[\S ]/, message: t("Invalid format.") }
      });
    } else {
      unregister("otherPositionTitle");
      setValue("otherPositionTitle", "");  // очищаємо значення
    }
  }, [selectedPosition, register, unregister, setValue, t]);

  const handleContinue = () => {
    registerNewUser(captchaResponse).then(() => navigate("/home"));
  };

  const onSubmit = (data) => {
    registerNewUser(captchaResponse, clearEmptyObjectValues(data)).then(() => navigate("/home"));
  };

  if (error && error.status !== 404) {
    throw new Error('Error in useGetUserOrganizationQuery')
  }

  if (isLoading) return <Loading />

  const existedOrganizationName = data?.name ?? ''
  const isOrganizationExists = Boolean(existedOrganizationName);
  return (
    <Dialog
      open={open}
      PaperProps={{
        style: {
          padding: 15,
          width: 600,
          maxWidth: "none",
        },
      }}
    >
      <DialogTitle sx={{ textAlign: "center" }}>
        <div>
          <img
            src="/logo48.png"
            alt="logo"
            width="48"
            height="48"
            style={{ display: "inline-block" }}
          />
        </div>
        {t("Tell us about your organization")}
      </DialogTitle>
      <DialogContent sx={{ textAlign: "center", overflow: "visible" }}>
        <Typography variant="body2" color="text.secondary" align="center">
          {t("organization_info_notice")}
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextField
            {...(isOrganizationExists ? {} : register("organizationName", {
              required: t("This field is required."),
              maxLength: { value: 200, message: t("Maximum limit is {{value}} characters.", { value: 200 }) },
              pattern: { value: /[\S ]/, message: t("Invalid format.") }
            }))}
            label={t("Organization name")}
            variant="outlined"
            fullWidth
            margin="dense"
            disabled={isOrganizationExists}
            error={!!errors.organizationName}
            helperText={errors.organizationName?.message}
            defaultValue={existedOrganizationName}
          />
          <TextField
            {...register("position", {
              required: t("This field is required."),
            })}
            select
            label={t("Your position")}
            variant="outlined"
            fullWidth
            margin="dense"
            error={!!errors.position}
            helperText={errors.position?.message ?? t('Choose the option that best describes your role in the organization') }
            defaultValue=""
          >
            <MenuItem value="" disabled>
              {t("Your position")}
            </MenuItem>
            {positions.map((option) => (
              <MenuItem key={option} value={option}>
                <Typography align="left">{option}</Typography>
              </MenuItem>
            ))}
          </TextField>
          {selectedPosition === "Other" && (
            <TextField
              {...(selectedPosition === "Other"
                  ? register("otherPositionTitle", {
                    required: t("This field is required."),
                    maxLength: { value: 200, message: t("Maximum limit is {{value}} characters.", { value: 200 }) },
                    pattern: { value: /[\S ]/, message: t("Invalid format.") }
                  })
                  : {}
              )}
              label={t("Enter your position")}
              variant="outlined"
              fullWidth
              margin="dense"
              error={!!errors.otherPositionTitle}
              helperText={errors.otherPositionTitle?.message}
              disabled={selectedPosition !== "Other"}
            />
          )}
          <Box sx={{ my: 3, display: "flex", justifyContent: "center" }}>
            <ReCAPTCHA
              sitekey={config.reCaptchaSitekey}
              onChange={setCaptchaResponse}
            />
          </Box>
          <Button
            startIcon={<Login />}
            variant="contained"
            type="submit"
            sx={{ textTransform: "none", whiteSpace: "nowrap" }}
            disabled={!captchaResponse || !isValid}
          >
            {t("Continue")}
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  );
}

export default function WelcomeNewUser() {
  const [open, setOpen] = useState(true);

  return (
    <header className="Root-header">
      <WelcomeDialog open={open} onClose={() => setOpen(false)} />
    </header>
  );
}
