import { Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useBackdrop } from '../../providers/BackdropProvider'
import { useSnackbar } from '../../providers/SnackbarProvider'
import { useProfile } from '../../providers/UserProfileProvider'
import { useCreateProjectMutation } from '../../services/projects'
import ProjectDialog from '../project/ProjectDialog'
import { useState } from 'react'
import { clearEmptyObjectValues } from '../../utils/ClearEmptyObjectValues'

export default function NewProjectButton({ organization }) {
  const { showAlert } = useSnackbar();
  const { setShowBackdrop } = useBackdrop();
  const [createProject] = useCreateProjectMutation();
  const navigate = useNavigate();
  const user = useProfile().user.sub;
  const { t } = useTranslation();
  const [isNewProjectDialogOpen, setIsNewProjectDialogOpen] = useState(false);

  const projectBody = {
    ownerUser: user,
    ownerOrganization: organization,
  };

  const onClick = () => {setIsNewProjectDialogOpen(true)}

  const onSubmit = async (data) => {
    setShowBackdrop(true);
    try {
      const createdProjectId = await createProject({...projectBody, ...clearEmptyObjectValues(data)}).unwrap();
      navigate(`/projects/${createdProjectId}`);
    } catch (err) {
      console.error(err);
      showAlert(t("Failed to create"));
    } finally {
      setShowBackdrop(false);
    }
  };
  return (
    <>
      <Button
        variant="contained"
        onClick={onClick}
        className="ui-new-project-button"
      >
        {t("Start new")}
      </Button>
      <ProjectDialog isOpen={isNewProjectDialogOpen} onSubmit={onSubmit} onClose={()=>{ setIsNewProjectDialogOpen(false) } } />
    </>
  );
}
