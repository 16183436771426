import axios from "axios";
import config from "../config";
import Project from "../model/Project";
import authService from "./authService";
import { downloadFile, zipAndDownload } from "./FileService";

export async function getProject(projectId) {
  const user = await authService.getUser();
  return axios.get(`${config.backendUrl}/projects/${projectId}`, {
    headers: { Authorization: `Bearer ${user.access_token}` },
  });
}

export async function exportProject(projectId) {
  const user = await authService.getUser();
  const project = await getProject(projectId).then((r) => new Project(r.data));

  const projectDoc = (({ id, ...prjData }) => JSON.stringify(prjData))(
    project.raw
  );

  await zipAndDownload(
    project
      .getFiles()
      .map((f) => ({
        name: f,
        data: downloadFile(project.id, f, user.access_token),
      }))
      .concat([{ name: "project.json", data: projectDoc }]),
    `Project_${project.refNumber}.zip`
  );
}

export async function startSegmentation(projectId) {
  const user = await authService.getUser();
  return axios.post(
    `${config.backendUrl}/projects/${projectId}/actions/startSegmentation`,
    {},
    {
      headers: { Authorization: `Bearer ${user.access_token}` },
    }
  );
}

export async function startCrownGeneration(projectId, tooth) {
  const user = await authService.getUser();
  return axios.post(
    `${config.backendUrl}/projects/${projectId}/actions/startCrownGeneration`,
    { tooth: tooth },
    {
      headers: { Authorization: `Bearer ${user.access_token}` },
    }
  );
}

export async function startMarginLine(projectId, tooth) {
  const user = await authService.getUser();
  return axios.post(
    `${config.backendUrl}/projects/${projectId}/actions/startMarginLine`,
    { tooth: tooth },
    {
      headers: { Authorization: `Bearer ${user.access_token}` },
    }
  );
}

export async function resetGeneration(projectId) {
  const user = await authService.getUser();
  return axios.post(
    `${config.backendUrl}/projects/${projectId}/actions/resetGeneration`,
    {},
    {
      headers: { Authorization: `Bearer ${user.access_token}` },
    }
  );
}

export async function searchUsers() {
  const user = await authService.getUser();
  return axios.get(`${config.backendUrl}/users`, {
    headers: { Authorization: `Bearer ${user.access_token}` },
  });
}

export async function getServerVersion() {
  const resp = await axios.get(`${config.backendUrl}/version`);
  return resp.status === 200 ? resp.data : "error";
}

export async function registerNewUser(captchaToken, { organizationName, position, otherPositionTitle }) {
  const user = await authService.getUser();
  const body = {}
  if (organizationName) { body.organizationName = organizationName }
  if (position) { body.position = position }
  if (otherPositionTitle) { body.otherPositionTitle = otherPositionTitle }

  return axios.post(
    `${config.backendUrl}/userprofile/registration`,
    body,
    {
      headers: {
        Authorization: `Bearer ${user.access_token}`,
        "captcha-token": captchaToken,
      },
    }
  );
}
