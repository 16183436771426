import { Box } from '@mui/material';
import Grid from '@mui/material/Grid2';
import {useProjectApi} from '../../../providers/ProjectApiProvider';
import useQuotaExceededDialog from '../../../hooks/useQuotaExceededDialog';
import { useEffect, useMemo, useState } from 'react';
import {startMarginLine} from '../../../services/apiService';
import { TeethTabs } from '../../widgets/TeethTabs';
import { TabPanel } from '../../widgets/TabPanel';
import '@intellident-ai/margin-line-editor/dist/index.css';
import {isUpperArch} from '../../../utils/arch';
import ToothMarginLine from './ToothMarginLine';

export default function MarginLine() {
  const {
    project: {
      id: projectId,
      upperArch,
      lowerArch,
      toothRestorations,
      ownerOrganization: organizationId,
    },
  } = useProjectApi();

  useEffect(() => {
    (async () => {
      const restorationsWithoutMarginLine = Object.values(toothRestorations).filter((restoration) => !restoration.hasMarginLine());
      for (const restoration of restorationsWithoutMarginLine) {
        try {
          await startMarginLine(projectId, restoration.tooth);
        } catch (e) {
          if (e.response.status === 429) {
            const showQuotaExceededDialog = useQuotaExceededDialog();
            await showQuotaExceededDialog(organizationId);
          }
        }
      }
    })();
  }, []);

  const restorations = useMemo(() => Object.values(toothRestorations), [toothRestorations]);

  const [activeTab, setActiveTab] = useState(restorations[0]?.tooth);

  const changeTab = (event, value) => setActiveTab(value);

  return (
    <Grid container spacing={3}>
      <Grid>
        <TeethTabs data={restorations} statusField="marginLine" tabTitle="Prep" value={activeTab} onChange={changeTab} />
      </Grid>
      <Grid sx={{ flex: 1, minWidth: 0 }}>
        {restorations.map((restoration) => (
          <TabPanel activeTab={activeTab} key={restoration.tooth} value={restoration.tooth}>
            <Box className="ui-scans-preview" sx={{ flex: 1 }}>
              <ToothMarginLine
                projectId={projectId}
                restoration={restoration}
                arch={isUpperArch(restoration.tooth) ? upperArch : lowerArch}
              />
            </Box>
          </TabPanel>
        ))}
      </Grid>
    </Grid>
  );
}
