import { Box, Button, Typography } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import { Link, NavLink } from 'react-router-dom'
import NewProjectButton from "../Components/projects/NewProjectButton";
import { HOME } from "../Components/system/Breadcrumbs";
import HomeTutorial from "../Components/tutorial/HomeTutorial";
import MainLayout from "../layout/MainLayout";
import { useProfile } from "../providers/UserProfileProvider";
import CrownsAndBridgesImg from "../assets/images/crowns-and-bridges.png"
import JawImg from "../assets/images/jaw.png"

function Home() {
  const { t } = useTranslation();
  const profile = useProfile();

  const HomeContent = () => {
    return (
      <Box
        sx={{
          maxWidth: 512,
          width: "100%",
          margin: "3rem auto 0",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: '0.75rem',
        }}
      >
        <Box sx={{
          display: 'flex',
          gap: '2rem',
          width: '100%',
        }}>
          <Box sx={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            alignItems: 'center',
            gap: '1.25rem',
            p: '1.25rem',
            border: '1px solid #d9d9d9',
            borderRadius: '12px',
          }}>
            <Typography variant="h6" sx={{
              textAlign: 'center', fontWeight: 400, lineHeight: '1.3',
              '& span': {
                display: 'block', textTransform: 'uppercase'
              }
            }}>
              <Trans components={{ span: <span /> }} i18nKey="restorationProject" />
            </Typography>
            <Box sx={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center' }}>
              <Box
                alt="Crowns And Bridges"
                component="img"
                src={CrownsAndBridgesImg}
              />
            </Box>
            <NewProjectButton organization={profile.organization.id} />
          </Box>
          <Box sx={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            alignItems: 'center',
            gap: '1.25rem',
            p: '1.25rem',
            border: '1px solid #d9d9d9',
            borderRadius: '12px',
          }}>
            <Typography variant="h6" sx={{
              textAlign: 'center', fontWeight: 400, lineHeight: '1.3',
              '& span': {
                display: 'block', textTransform: 'uppercase'
              }
            }}>
              <Trans components={{ span: <span /> }} i18nKey="splintProject" />
            </Typography>
            <Box sx={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center' }}>
              <Box
                alt="jaw"
                component="img"
                src={JawImg}
              />
            </Box>
            <Button disabled variant="contained">{t("Start new")}</Button>
          </Box>

        </Box>
        <Box sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          gap: '0.75rem',
          textTransform: 'uppercase',
          fontWeight: 500,
          fontSize: '15px',
          mt: '1.25rem',
          '&::before, &::after': {
            content: "''",
            height: '1px',
            backgroundColor: '#d9d9d9',
            flex: 1,
          }
        }}>
          {t("or")}
        </Box>
        <Button component={NavLink} to="/projects" className="ui-link-to-existing-projects">{t("Go to existing projects")}</Button>
      </Box>
    );
  };

  return (
    <MainLayout title={t("Welcome")} breadcrumbs={[HOME]}>
      <HomeTutorial />
      <HomeContent></HomeContent>
    </MainLayout>
  );
}

export default Home;
