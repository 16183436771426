import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";
import { useSettings } from "../../providers/UserProfileProvider";
import { getToothLabel } from "../../utils/arch";

function labels(arch, notation) {
  const incrementValue = arch === 'lower' ? 17 : 1;

  const teeth = Array.from({length: 16}, (_, i) => i + incrementValue);

  const entries = teeth
    .map((t) => ({ key: t, label: getToothLabel(t, notation) }))
    .sort((a, b) => a.label - b.label);

  return entries.map(({ key, label }) => (
    <MenuItem key={key} value={key}>
      {label}
    </MenuItem>
  ));
}

export default function ToothNumberSelect(props) {
  const { value, label, onChange, jaw, multiple, error } = props;
  const { notation } = useSettings();

  return (
    <FormControl error={error} fullWidth sx={{ maxWidth: "20ch" }}>
      <InputLabel>{label}</InputLabel>
      <Select
        value={value || ""}
        label={label}
        onChange={(e) => onChange(e.target.value)}
        multiple={multiple}
      >
        {labels(jaw, notation)}
      </Select>
    </FormControl>
  );
}
