import { Card, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { Box } from "@mui/system";
import { useMemo } from "react";
import config from "../../config";
import { useSettings } from "../../providers/UserProfileProvider";
import { getToothLabel } from "../../utils/arch";

export default function SegmentationLegend(props) {
  const { arch } = props;

  const { notation } = useSettings();

  const colorMap = useMemo(() =>
    arch === "none"
      ? {}
      : Object.fromEntries(
          config.colorMaps[arch].map((e, i) => [i, e.slice(1)])
        )
  );
  const teeth =
    arch === "upper"
      ? [...Array(14).keys()].map((x) => x + 2)
      : [...Array(14).keys()].map((x) => 31 - x);

  return (
    <Card
      sx={{
        diplay: "block",
        width: "320px",
        padding: "0px 5px 5px 5px",
        margin: "auto",
        color: "white",
        backgroundColor: "rgb(51, 77, 102)",
      }}
    >
      <Grid container>
        <Grid
          container
          sx={{
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {teeth.map((x) => (
            <Grid key={x}>
              <Box
                sx={{
                  width: "22px",
                  height: "20px",
                  textAlign: "center",
                }}
              >
                <Typography variant="caption">
                  {arch === "none" ? "" : getToothLabel(x, notation)}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
        <Grid
          container
          sx={{
            alignItems: "center",
            justifyContent: "center",
            height: "20px",
          }}
        >
          {arch !== "none" &&
            teeth
              .map((x) => (arch === "upper" ? x - 1 : x - 17))
              .map((x) => (
                <Grid key={x}>
                  <Box
                    sx={{
                      width: "22px",
                      height: "20px",
                      backgroundColor: `rgb(${colorMap[x][0]}, ${colorMap[x][1]}, ${colorMap[x][2]})`,
                    }}
                  />
                </Grid>
              ))}
        </Grid>
      </Grid>
    </Card>
  );
}
