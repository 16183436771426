import { createApi } from "@reduxjs/toolkit/query/react";
import config from "../config";
import axiosBaseQuery from "../utils/axiosBaseQuery";

export const userProfileApi = createApi({
  reducerPath: "userProfileApi",
  baseQuery: axiosBaseQuery({ baseUrl: config.backendUrl }),
  tagTypes: ["userprofile"],
  endpoints: (builder) => ({
    getUserProfile: builder.query({
      query: () => ({ url: "/userprofile" }),
      providesTags: ["userprofile"],
    }),
    getUserOrganization: builder.query({
      query: () => ({ url: "/userprofile/organization" }),
      providesTags: ["userprofile"],
    }),
    updateUserSettings: builder.mutation({
      query: (patch) => ({
        url: "/userprofile/settings",
        method: "PATCH",
        body: patch,
      }),
      invalidatesTags: ["userprofile"],
    }),
  }),
});

export const { useGetUserProfileQuery, useGetUserOrganizationQuery, useUpdateUserSettingsMutation } =
  userProfileApi;
